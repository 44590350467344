<template>
    <div class="alert-container position-fixed top-0 start-0" :class="add_class">
        <div class="dimm w-100 h-100 bg-dark bg-opacity-50 position-absolute top-0 start-0" v-show="!auto_hide"></div>
        <div class="content position-absolute start-50 bg-body bg-opacity-85 backdrop-blur-10 rounded-2 text-center" :class="{'top-50':!auto_hide,'autohide':auto_hide}">
            <div class="inner" :class="{'p-4':!auto_hide,'p-3':auto_hide}">
                <!-- <i class="pt-1 pe-1 fal fa-times text-white position-absolute top-0 end-0 translate-middle-x" v-if="auto_hide" @click="ok()"></i> -->
                <div v-html="msg"></div>
            </div>
            <div class="border-gray-relative-500 flex-center-center pb-4 px-4 gap-2" v-show="!auto_hide">
                <button class="py-3 fs-px-12 fw-bold btn bg-gray-100 w-50 cancel_btn" v-if="is_confirm" @click="cancel()">{{cancel_btn_txt}}</button>
                <button class="py-3 fs-px-12 fw-bold btn btn-transparent bg-yellow-1 w-50 ok_btn" @click="ok()">{{ok_btn_txt}}</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        idx: {
            type: Number
        },
        msg: {
            type: String
        },
        is_confirm: {
            type: Boolean,
            default: false
        },
        ok_btn_txt: {
            type: String,
            default: 'OK'
        },
        cancel_btn_txt: {
            type: String,
            default: 'CANCEL'
        },
        add_class: {
            type: String,
            default: ''
        },
        auto_hide: {
            type: Boolean,
            default: false
        },
    },
    methods:{
    },
}
</script>

<style lang="scss" scoped>
    .alert-container{
        width: 100vw !important;
        max-width: 100vw !important;
        height: 100vh;
        z-index: 9999;

        .dimm{
            animation: fadeIn 0.2s;
        }
        .content{
            width: 90%;
            max-width: 400px;
            min-width: 260px;
            transform-origin: 0 0;
            animation: zoomin 0.2s;
            animation-fill-mode: forwards;
            
            .border-top{
                border-width: 0.5px !important;
            }
            &.autohide{
                // background-color: #575757 !important;
                background-color: #494949b4 !important;
                color: #fff !important;
                bottom: 60px;
            }
        }
    }
    @keyframes fadeIn{
        from { opacity: 0; }
        to { opacity: 1; }
    }
    @keyframes zoomin{
        from { transform: scale(0.8) translate(-50%, -50%); }
        to { transform: scale(1) translate(-50%, -50%); }
    }
</style>