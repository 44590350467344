<template>
  <!-- List는 리스트 묶음, ListItem은 리스트 단일 아이템 한개 표출 -->
    <div class="pb-2" >
        <ul class="d-flex px-3 deal-list" :class="{'horizontal':horizontal}">
            <Item v-for="(item, index) in list" :key="index" :item="item" :hot_deal="hot_deal" :horizontal="horizontal" :index="index" :show ="show"/>
            <li class="p-3 rounded-1 bg-white text-center overflow-hidden item d-flex flex-column fs-px-12 w-100" v-if="list.length<1&&list">No Deals
            </li>
            <li class="bg-yellow-1 rounded-2 text-center flex-center-center flex-column px-3" @click="$router.push(`${horizontal}`)" v-if="horizontal">
                <i class="far fa-arrow-right fa-lg mb-2"></i>
                <small class="fw-bold">More</small>
            </li>
        </ul>
    </div>
</template>
<script>
import Item from '@/components/deal/ListItem.vue'

export default {
    name: 'Deal',
    components: {
        Item
    },
    data(){
        return{

        }
    },
    computed:{
    },
    props:{
        list:{
            type:Array
        },
        hot_deal: false,
        horizontal: false,
        show : true,
    },
    methods:{
    }
}
</script>