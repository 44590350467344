
export default{
    install(Vue){
        Vue.prototype.$copy = function(txt) {
            const textarea = document.createElement('textarea');
            textarea.value = txt;
            document.body.appendChild(textarea);
            textarea.select();
            document.execCommand('copy');
            document.body.removeChild(textarea);

            Vue.prototype.$pp({
                msg: 'Copied!', 
                auto_hide:true
            })
        };

        Vue.prototype.$hide_last_str = function(txt) {
            let _txt = txt;
            let _hide = '';
            let half = Math.floor(txt.length/2);

            _txt = txt.substr(0, half);

            for (let i=0; i<txt.length-half; i++) {
                _hide += '*'
            }
            
            _txt += _hide;

            return _txt
        };
        
        Vue.prototype.$formatDate_full = function(dateTimeStr) {
            const date = new Date(dateTimeStr);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');
            const milseconds = String(date.getMilliseconds()).padStart(3, '0');

            return `${year}-${month}-${day} T${hours}:${minutes}:${seconds} ${milseconds}`;
        };

        Vue.prototype.$formatDate_yymmdd = function(dateTimeStr) {
            const date = new Date(dateTimeStr);
            const year = String(date.getFullYear()).substring(2, 4);
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');

            return `${year}-${month}-${day}`;
        };

        Vue.prototype.$formatDate_yymmdd_hhmm = function(dateTimeStr) {
            const date = new Date(dateTimeStr);
            const year = String(date.getFullYear()).substring(2, 4);
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');

            return `${year}-${month}-${day} ${hours}:${minutes}`;
        };
    }
}
