<template>
  <div class="main">
    <h6 class="text-white mx-3 mb-3">Closed Deals Announcement</h6>
    <ul class="d-flex px-3 w-100 flex-nowrap overflow-auto text-nowrap gap-2 children-w-80"  v-if="closedList.length > 0">
      <CloseListItem v-for="(item, index) in closedList.slice(0,5)" :key="index" :item="item" :index="index" class="item" />
      <li class="bg-yellow-1 rounded-2 text-center flex-center-center flex-column px-3" @click="$router.push('/deal/list/closed')">
        <i class="far fa-arrow-right fa-lg mb-2"></i>
        <small class="fw-bold">More</small>
      </li>
    </ul>

    <!-- get dashboard -->
    <h6 class="text-white mx-3 mt-4 mb-2" >HOT DEALS</h6>
    <List :list="hotList" :hot_deal="true" :show="true"></List>

    <!-- get deallist -->
    <div class="flex-between-center">
      <h6 class="text-white mx-3 mt-4 mb-2" >OPEN DEALS</h6>
      <h6 class="text-white mx-3 mt-4 mb-2" @click="$router.push('/deal/list/open')">See All</h6>
    </div>
    
    <div class="position-relative">
      <button class="slide_nav_btn shadow position-absolute start-0 translate-middle-y ms-2" @click="scrollPrev">
        <i class="text-burgundy-0 fas fa-chevron-left"></i>
      </button>
      <button class="slide_nav_btn shadow position-absolute end-0 translate-middle-y me-2" @click="scrollNext">
        <i class="text-burgundy-0 fas fa-chevron-right"></i>
      </button>
      <div class="d-flex flex-column px-3 deal-list horizontal overflow-auto" ref="slider" style="scroll-behavior: smooth">
        <ListEven :list="openListEven" class="d-flex" :show="true"></ListEven>
        <ListOdd :list="openListOdd"  class="mb-4 d-flex" :show="true"></ListOdd>
      </div>
    </div>
    
  </div>
</template>

<script>
// @ is an alias to /src
import CloseListItem from '@/components/deal/CloseListItem.vue'
import Header from '@/components/common/Header.vue'
import List from '@/components/deal/List.vue'
import ListEven from '@/components/deal/ListEven.vue'
import ListOdd from '@/components/deal/ListOdd.vue'
import Footer from '@/components/common/Footer.vue'
const CryptoJS = require("crypto-js");

export default {
  name: 'Home',
  components: {
    CloseListItem,
    Header, List, Footer,ListEven,ListOdd
  },
  props:{
    list:{
        type:Array
    },
  },
  data(){
    return{
      hotList : [],
      openListEven : [],
      openListOdd : [],
      openList:[
        
      ],
      
      closedList:[],
    }
  },
  mounted(){
    this.GetDealHotList();
    this.GetCloseList();
    this.GetDealList();
  },
  methods:{
    
    scrollNext() {
      const slider = this.$refs.slider;
      const slider_item = slider.childNodes[0].children[0];
      const sliderWidth = slider_item.clientWidth + 8;
      slider.scrollLeft += sliderWidth;
    },
    scrollPrev() {
      const slider = this.$refs.slider;
      const slider_item = slider.childNodes[0].children[0];
      const sliderWidth = slider_item.clientWidth + 8;
      slider.scrollLeft -= sliderWidth;
    },
    GetCloseList(){
      this.$http.post('/member/main/GetCloseDealList',{}).then(
        (res) =>  {
            if(res.status == 200){
                this.loading = false;
                if(res.data.code =="200"){
                    const e_body = res.data.body;
                    const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                    const d_res = bytes.toString(CryptoJS.enc.Utf8);
                    const body = JSON.parse(d_res)
                    this.closedList = body.list;
                }else if(res.data.code =="9999"){
                    this.$store.dispatch('logout').then(
                        ()=>{
                        location.href = `/signin`;
                        }
                    );
                }
            }
        }   
      )
    },
    
    GetDealHotList(){
      this.$http.post('/member/main/GetHotDealList',{}).then(
        (res) =>  {
            if(res.status == 200){
                this.loading = false;
                if(res.data.code =="200"){
                    const e_body = res.data.body;
                    const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                    const d_res = bytes.toString(CryptoJS.enc.Utf8);
                    const body = JSON.parse(d_res)
                    this.hotList = body.list;
                }else if(res.data.code =="9999"){
                    this.$store.dispatch('logout').then(
                        ()=>{
                        location.href = `/signin`;
                        }
                    );
                }
            }
        }   
      )
    },
    GetDealList(){

      this.$http.post('/member/main/GetOpenDealList',{}).then(
        (res) =>  {
            if(res.status == 200){
                this.loading = false;
                if(res.data.code =="200"){
                    const e_body = res.data.body;
                    const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                    const d_res = bytes.toString(CryptoJS.enc.Utf8);
                    const body = JSON.parse(d_res)
                    this.openList = body.list;
                    this.CheckDivList()
                }else if(res.data.code =="9999"){
                    this.$store.dispatch('logout').then(
                        ()=>{
                        location.href = `/signin`;
                        }
                    );
                }
            }
        }   
      )
    },
    CheckDivList(){
      const list = this.openList;
      
      let even = [];
      let odd = [];
      for (const [index,el] of list.entries()) {
        if(index % 2 ==0){
          even.push(el)
        }else if(index % 2 ==1){
          odd.push(el)
        }
      }

      this.openListEven = even;
      this.openListOdd = odd
    }
  }

}
</script>
<style lang="scss" scoped>
::-webkit-scrollbar { 
  display: none !important; // 윈도우 크롬 등
}

.children-w-80{
  .item{
    width: 80% !important;
    min-width: 250px;
  }
}
.deal-list > div::after{
    display: block;
    content: '';
    width: 7px;
    height: auto;
    flex-shrink: 0;
}
</style>