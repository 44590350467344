<template>
  <div v-if="banner.length > 0">
        <VueSlickCarousel :arrows="false" :dots="false" v-bind="settings" >
            <div v-for="(item,index) in banner" :key="index">
                <!-- <a :href="item.link" :target="item.link===''?'_self':'_blank'"><img :src="item.src" alt="banner"  class="img-fluid"></a> -->
                <a :href="item.link" target="_blank"><img :src="item.path" alt="banner"  class="img-fluid" ></a>
            </div>
        </VueSlickCarousel>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
const CryptoJS = require("crypto-js");

export default {
    name: 'MyComponent',
    components: { VueSlickCarousel },
    data(){
        return{
            banner:[],
            settings:{
                "autoplay": true,
                "speed": 500,
                "autoplaySpeed": 3000,
                
            },
            type : 'M'
        }
    },
    mounted(){
        this.GetBanner();
    },
    methods : {
        GetBanner(){
            
            const type = this.type;
            const body = {type};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

            this.$http.post('/member/common/GetBanner',{req}).then(
                (res) =>  {
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res)
                            const list = body.list;
                            this.banner = list;
                            console.log(list);
                        }else if(res.data.code =="9999"){
                            this.$store.dispatch('logout').then(
                                ()=>{
                                location.href = `/signin`;
                                }
                            );
                        }
                    }
                }   
            )

        }
    }
}
</script>

<style lang="scss" scoped>
.banner{
    background-color: #65274A;
    height: 150px;
}
.croco-icon{
    height: 100px;
    left: 20px;
    bottom: 12px;
}
.arrow-box{
    position: absolute;
    letter-spacing: 1px;
    padding-right: 10px;
    right: 15px;
    bottom: 15px;
    font-size: 13px;
    border-bottom: 2px solid #FFB800;
    .arrow{
        position: absolute;
        bottom: -5px;
        right: 0;
        border: solid #FFB800;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(-45deg);
    }
}
</style>