<template>
    <li class="flex-between-center rounded-1 bg-white text-center overflow-hidden item py-3 w-100 flex-shrink-0" @click="goTodetail">
        <div class="flex-center-center flex-column w-50 px-3">
            
            <small class="fs-px-11">BUYER</small>
            <b class="fs-px-12" v-if="item.type=='E'">{{item.buyer_owner}}</b>
            <b class="fs-px-12" v-if="item.type=='C'">CANCEL</b>
            <b-avatar class="avatar my-2 bg-yellow-1" height="50" :src="item.buyer_profile"></b-avatar>
            <b class="fw-bold fs-px-13" v-if="item.type=='E'" :class="CheckIconColor(item.buyer_profit)">
                <i class="fas me-1" :class="CheckIconClass(item.buyer_profit)"></i>
                {{item.buyer_profit}}%
            </b>
            <b class="text-danger fw-bold fs-px-13" v-if="item.type=='C'">
                Seller Cancel
            </b>
            <b class="text-danger fw-bold fs-px-13" v-if="item.type=='Y'">
                OCB Drawing System ON
            </b>
            <small class="fw-bold" v-if="item.type =='E'">GOT {{item.benefit_price}} {{item.benefit_coin}}</small>

        </div>
        <div class="flex-center-center flex-column border-start w-50 px-3">
            <small class="fs-px-11">SELLER</small>
            <b class="fs-px-12">{{item.dealer_owner}}</b>
            <b-avatar class="avatar my-2 bg-yellow-1" height="50" :src="item.dealer_profile"></b-avatar>
            <b class="fw-bold fs-px-13" v-if="item.type=='E'" :class="CheckIconColor(item.dealer_profit)">
                <i class="fas me-1" :class="CheckIconClass(item.dealer_profit)"></i>
                {{item.dealer_profit}}%
            </b>
            <b class="text-danger fw-bold fs-px-13" v-if="item.type=='C'">
                Seller Cancel
            </b>
            <b class="text-danger fw-bold fs-px-13" v-if="item.type=='Y'">
                OCB Drawing System ON
            </b>
            <small class="fw-bold" v-if="item.type =='E'">GOT {{item.deal_price}} {{item.deal_coin}}</small>
        </div>
    </li>
</template>
<script>
import { BAvatar } from 'bootstrap-vue'

export default {
    components: {
        BAvatar
    },
    data(){
        return{
        }
    },
    computed:{
    },
    props:{
        item: {
            type: Object,
            default: function(){
                return{
                    code :'',
                    benefit_price :'',
                    benefit_coin :'',
                    buyer_profit :'',
                    buyer_owner :'',
                    buyer_profile :'',
                    deal_coin :'',
                    dealer_profit :'',
                    deal_price :'',
                    dealer_profile :'',
                    dealer_owner :'',
                    
                }                
            },
        },
        index: {
            type: Number,
            default: null,
        }
    },
    methods:{
        goTodetail(){
            this.$router.push({
                name: 'DealDetail',
                params: {
                    idx: this.item.code,
                    item: this.item
                }
            })
        },
        CheckIconColor(per){
            if(per ==0){
                return "text-black"
            }else if(per >0){
                return "text-danger"
            }else if(per <0){
                return "text-primary"
            }

        },
        CheckIconClass(per){
            if(per ==0){
                return "fa-minus"
            }else if(per >0){
                return "fa-arrow-alt-up"
            }else if(per <0){
                return "fa-arrow-alt-down"
            }

        }
    }
}
</script>
<style scoped>
    .avatar{
    height: 50px !important;
    width: 50px !important;
    }
</style>