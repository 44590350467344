<template>
    <header class="p-3">
        <div v-if="ticket">
            <router-link to="/upload-ticket">
                <img src="@/assets/img/ticket.svg" alt="ticket" height="30">
            </router-link>
        </div>
        <div class="d-flex align-self-center">
            <div class="fs-6" v-if="link != 'hide'">
                <span class="text-yellow-1 text-opacity-50" @click="ClickEvent()">
                    <img src="@/assets/img/layout_icon/chevron_left.svg" alt="prev" width="30" class="cursor-pointer">
                </span>
            </div>
        </div>
        <router-link to="/" class="position-absolute start-50 translate-middle-x">
            <img src="@/assets/img/logo.svg" alt="logo" height="55">
        </router-link>
        <router-link to="/mypage" v-if="$store.state.login">
            <img src="@/assets/img/user-icon.svg" alt="user-icon" height="34"  v-if="profile_img == ''">
            <img :src="profile_img" alt="profile" width="34" height="34" class="rounded-circle object-fit-cover" v-else>
        </router-link>
        <router-link to="/signin" v-if="!$store.state.login&&loginBtn">
            <svg width="32" height="32" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M27 12.9531C16.0406 16.0096 8 26.0658 8 38C8 52.3594 19.6406 64 34 64C48.3594 64 60 52.3594 60 38C60 26.0658 51.9594 16.0096 41 12.9531V21.4118C47.4639 24.1428 52 30.5416 52 38C52 47.9411 43.9411 56 34 56C24.0589 56 16 47.9411 16 38C16 30.5417 20.5361 24.1428 27 21.4118V12.9531Z" fill="#FFB800"/>
                <path d="M30 7.27273C30 5.46525 31.3431 4 33 4H35C36.6569 4 38 5.46525 38 7.27273V24.7273C38 26.5347 36.6569 28 35 28H33C31.3431 28 30 26.5347 30 24.7273V7.27273Z" fill="#FFB800"/>
            </svg>
        </router-link>
    </header>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: '',
        },
        ticket: {
            type: Boolean,
            default: false,
        },
        link: {
            type: String,
            default: '',
        },
        loginBtn:{
            type: Boolean,
            default: false,
        }

    },
    methods: {
        ClickEvent() {
            if (this.link == '') {
                this.goBack();
            } else {
                this.goLink();
            }
        },
        goBack() {
            this.$router.go(-1);
        },
        goLink() {
            this.$router.push(this.link);
        },
    },
    data(){
        return{
            profile_img: this.$store.state.profile,
            login : this.$store.state.login

        }
    }

}
</script>

<style lang="scss" scoped>
header{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 90px;
}
</style>